export default grapesjs.plugins.add('HomeSlider', (editor, options) => {
	editor.BlockManager.add('HomeSlider', {
		category: 'Commerce',
		label: 'Home Slider',
		attributes: { class: 'gjs-fonts gjs-f-b1' },
		content: `<div id="carousel-splash" class="carousel slide" data-ride="carousel" data-interval="false" data-gjs-type="HomeSlider">
			<ol class="carousel-indicators" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
					<li data-target="#carousel-splash" data-slide-to="0" class="active" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false"></li>
					<li data-target="#carousel-splash" data-slide-to="1" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false"></li>
			</ol>
			<div class="carousel-inner" role="listbox">
				<div class="item slider-position" data-gjs-type="HomeSliderItem">
					<div data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
						<a href="https://www.heidelberg.com/global/en/about_heidelberg/news_and_events/packaging_news_1/packaging_printing.jsp#video-1365906-0 " class="splashPromoLink" data-promo="HomepageSlider Packaging" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
							<img src="` + CCRZ.pagevars.themeBaseURL + `images/ccc-preview-slider-1-1920x550.jpg" alt="HomepageSlider Packaging" class="center-block slider-image-size" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false" />
						</a>
					</div>
					<div class="col-xs-12 col-xm-12 slider-textbox slider-textbox-position" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
						<div  data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
							<p class="slider-firstheader" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">Packaging News.</p>
						</div>
						<div  data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
							<p class="slider-secondheader" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">Partners in Performance.</p>
						</div>
						<div class="slider-underline" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false"></div>
						<div class="slider-content" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
							<p data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">A few years ago, packaging specialist Cartographic of Mexico was looking for a partner that could provide them with a complete production line, from workflow to press and postpress equipment. They tested, compared – and chose Heidelberg. And chose it again. Today, Juan Castañon, managing director of Cartographic, explains how this partnership helped boost his company’s performance.</p>
						</div>
						<a href="https://www.heidelberg.com/global/en/about_heidelberg/news_and_events/packaging_news_1/packaging_printing.jsp#video-1365906-0" class="slider-button-text btn btn-lg btn-primary splashPromoLink" data-promo="HomepageSlider Packaging" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">Watch the video</a>
					</div>
				</div>
				<div class="item slider-position" data-gjs-type="HomeSliderItem">
					<div data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
						<a href="https://www.heidelberg.com/global/en/about_heidelberg/news_and_events/commercial_news_1/productivity/productive_customers.jsp " class="splashPromoLink" data-promo="HomepageSlider Commercial" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
							<img src="` + CCRZ.pagevars.themeBaseURL + `images/ccc-preview-slider-2-1920x550.jpg" alt="HomepageSlider Commercial" class="center-block slider-image-size" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false" />
						</a>
					</div>
					<div class="col-xs-12 col-xm-12 slider-textbox slider-textbox-position" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
						<div data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
							<p class="slider-firstheader" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">Commercial News.</p>
						</div>
						<div data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
							<p class="slider-secondheader" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">So, what is true productivity?</p>
						</div>
						<div class="slider-underline" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false"></div>
						<div class="slider-content" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
							<p data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">What is it exactly that profitable printers do better than others? What do they do that makes them earn higher profits? And which impact does the performance of a single press have on the overall productivity and profitability of a print shop?</p>
						</div>
							<a href="https://www.heidelberg.com/global/en/about_heidelberg/news_and_events/commercial_news_1/productivity/productive_customers.jsp" class="slider-button-text btn btn-lg btn-primary splashPromoLink" data-promo="HomepageSlider Commercial" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">What customers say</a>
					</div>            
				</div>
			</div>
			<a class="left carousel-control" href="#carousel-splash" role="button" data-slide="prev" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
				<i class="icon-collapse_left" aria-hidden="true" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false"></i>
				<span class="sr-only" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">previous slide</span>
			</a>
			<a class="right carousel-control" href="#carousel-splash" role="button" data-slide="next" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">
				<i class="icon-collapse_right" aria-hidden="true" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false"></i>
				<span class="sr-only" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false">next slide</span>
			</a>
		</div>`
	});

	// Get DomComponents module
	var comps = editor.DomComponents;
	
	// Get the model and the view from the default Component type
	var defaultType = comps.getType('default');
	var defaultModel = defaultType.model;
	var defaultView = defaultType.view;

	// start configuration Teaser-Tile-Big
	comps.addType('HomeSlider', {
		// Define the Model
		model: defaultModel.extend({
			// Extend default properties
			defaults: Object.assign(
				{},
				defaultModel.prototype.defaults, {
					// Can't drop other elements inside it
					droppable: false,
					// Traits (Settings)
					traits: [],
					attributes: {}
				}
			),
			init() {},
			handleTraitChange() {
				this.attributes.content = this.generateHTML();
				this.view.el.innerHTML = this.generateHTML();
				$(this.view.el).removeAttr('data-initial-state');
				delete this.view.attr['data-initial-state'];
				delete this.attributes.attributes['data-initial-state'];
			},
			toHTML: function () {
				return this.generateHTML();
			},
			generateHTML() {
				// remove the default component preview items before save into frontend
				// remove only from slider items and the indicator bullet points in the bottom of the slider
				this.view.$el.find(`
					[data-gjs-type="HomeSliderItem"] [data-gjs-type="default"], 
					.carousel-indicators[data-gjs-type="default"],
					.carousel-control[data-gjs-type="link"]
				`).remove();

				let elementString = '';
				var $sliderItems = $(this.view.el.outerHTML).find('[data-gjs-type="HomeSliderItem"]');

				var sliderItemsString = $sliderItems.prevObject.html();
				sliderItemsString = sliderItemsString.replace(/data-highlightable=\"(.*?)\"/g, '');
				sliderItemsString = sliderItemsString.replace(/isimglink=\"(.*?)\"/g, '');
				sliderItemsString = sliderItemsString.replace(/imageposition=\"(.*?)\"/g, '');
				sliderItemsString = sliderItemsString.replace(/isimglink=\"(.*?)\"/g, '');
				sliderItemsString = sliderItemsString.replace(/imglink=\"(.*?)\"/g, '');
				sliderItemsString = sliderItemsString.replace(/imgsrc=\"(.*?)\"/g, '');
				sliderItemsString = sliderItemsString.replace(/imgtitle=\"(.*?)\"/g, '');
				sliderItemsString = sliderItemsString.replace(/isinfoboxshown=\"(.*?)\"/g, '');
				sliderItemsString = sliderItemsString.replace(/headlinefirst=\"(.*?)\"/g, '');
				sliderItemsString = sliderItemsString.replace(/headlinesecond=\"(.*?)\"/g, '');
				sliderItemsString = sliderItemsString.replace(/contenttext=\"(.*?)\"/g, '');
				sliderItemsString = sliderItemsString.replace(/btnpritxt=\"(.*?)\"/g, '');
				sliderItemsString = sliderItemsString.replace(/btnprihref=\"(.*?)\"/g, '');
				
				// set first element as active slide
				if($sliderItems.length > 0) $sliderItems[0].outerHTML = $sliderItems[0].outerHTML.replace('class="item slider-position"', 'class="item slider-position active"');
				
				elementString += `
				<div id="carousel-splash" class="carousel slide" data-ride="carousel" data-interval="false" data-gjs-type="HomeSlider">`;
					if($sliderItems.length > 1) {
						elementString += `
						<ol class="carousel-indicators" >`;
						for (let i = 0; i < $sliderItems.length; i++) {
							elementString += `<li data-target="#carousel-splash" data-slide-to="` + i + `"`; 
							
							if(i == 0) {
								elementString += ` class="active" `;
							}
							
							elementString += `></li>`;
						}
						
						elementString += `
						</ol>`;
					}

				elementString += `
					<div class="carousel-inner" role="listbox">
					` + sliderItemsString + `
					</div>`;

					if($sliderItems.length > 1) {
						elementString += `
						<a class="left carousel-control" href="#carousel-splash" role="button" data-slide="prev" >
							<i class="icon-collapse_left" aria-hidden="true" ></i>
							<span class="sr-only" >previous slide</span>
						</a>
						<a class="right carousel-control" href="#carousel-splash" role="button" data-slide="next" >
							<i class="icon-collapse_right" aria-hidden="true" ></i>
							<span class="sr-only" >next slide</span>
						</a>`;
					}
				elementString += `
				</div>`;
				
				return elementString;
			}
		},
			// The second argument of .extend are static methods and we'll put inside our
			// isComponent() method. As you're putting a new Component type on top of the stack,
			// not declaring isComponent() might probably break stuff, especially if you extend
			// the default one.
			{
				isComponent(el) {
					if (el.getAttribute && el.getAttribute('data-gjs-type') == 'HomeSlider') {
						return {
							type: 'HomeSlider'
						};
					}
				}
			}
		)
	});
});
